'use strict';
function PcMessageTranslator(privateChat) {
  var languageShortNames = {
    "english": "en",
    "russian": "ru",
    "french": "fr",
    "japanese": "ja",
    "chinese": "zh",
    "lithuanian": "lt",
    "spanish": "es",
    "bulgarian": "bg",
    "italian": "it",
    "hungarian": "hu",
    "hebrew": "he",
    "dutch": "nl",
    "german": "de",
    "greek": "el",
    "turkish": "tr",
    "portuguese": "pt",
    "estonian": "et",
    "czech": "cs",
    "romanian": "ro",
    "filipino": "tl",
    "korean": "ko",
  };
  var translationDisabled = true;
  var emojiRegexp = null;

  function disableTranslation() {
    translationDisabled = true;
    $(".btn-translate", privateChat.getChatContainer()).addClass("hidden");
  }

  function translate(text, sourceLanguage, destLanguage, callback) {
    var settings = privateChat.getSettings();
    if (translationDisabled) return;
    if (PcChatUtils.getItem("auto_translate", "0") != "1") return;
    if (!settings["translate"]) return;
    if ($.fn.EmojiPicker && $.fn.EmojiPicker.getEmojisUnicodeRegexPattern() != null) {
      emojiRegexp = new RegExp("\s*" + $.fn.EmojiPicker.getEmojisUnicodeRegexPattern() + "\s*", "g");
    }
    if (emojiRegexp != null) text = text.replace(emojiRegexp, ' ');
    text = text.trim();
    if (text.length == 0) return;
    destLanguage = convertLanguageToIso(destLanguage);

    $.ajax({
      "url": 'https://cdn.online-webcam.net/translate.jsp',
      "dataType": "jsonp",
      "data": {
        "i": settings["translate"]["i"],
        "h": settings["translate"]["h"],
        "salt": settings["translate"]["salt"],
        "t": text,
        "s": sourceLanguage,
        "d": destLanguage
      },
      "success": function (res) {
        if (res.error) {
          if (res.error > 400 && res.error < 500) disableTranslation();
          return;
        }
        if (res.t && res.t != text) {
          callback(res.t);
        }
      }
    });
  }

  function convertLanguageToIso(language) {
    if (language.length > 3) {
      if (languageShortNames[language]) return languageShortNames[language];
      return "en";
    }
    return language;
  }

  this.bind("translate", function (data) {
    var sourceLanguage = null;
    translate(data.message, sourceLanguage, data.destLanguage, function(t) {
      data.container.find(".msg-body").append($('<div class="translated-text"/>').text(t));
    });
  });

  $("body").on("PcMessageTranslator.Init", function(event, privateChat) {
    var settings = privateChat.getSettings();
    $(".btn-translate", privateChat.getChatContainer()).on("click", function() {
      $(this).toggleClass("active");
      PcChatUtils.setItem("auto_translate", $(this).hasClass("active")?"1":"0");
    }).addClass(PcChatUtils.getItem("auto_translate", "0") == "1"?"active":"");

    if(settings["translate"]) {
      $.ajax({
        "url": 'https://cdn.online-webcam.net/translate.jsp',
        "dataType": "jsonp",
        "data": {
          "i" : settings["translate"]["i"],
          "h" : settings["translate"]["h"],
          "salt" : settings["translate"]["salt"],
        },
        "success": function(res) {
          if(res.error) {
            if(res.error > 400 && res.error < 500) disableTranslation();
            return;
          }
          translationDisabled = false;
          $(".btn-translate", privateChat.getChatContainer()).removeClass("hidden");
          if(PcChatUtils.getItem("auto_translate", null) == null) {
            PcChatUtils.setItem("auto_translate", "1");
          }
          $(".btn-translate", privateChat.getChatContainer()).addClass(PcChatUtils.getItem("auto_translate", "0") == 1 ? "active":"");
        }
      });
    }
  });
}

MicroEvent.mixin(PcMessageTranslator);