function PcWebSocketTransport(settings) {
  //private
  var _this = this;
  var opts = {};
  var defaults = {
    "waitingConfirmation": false,
    "serverUri" :"localhost"
  };
  var closeReason = {};

  var _chat = PrivateChat.getInstance();

  //constructor
  opts = $.extend(true, defaults, settings);

  var params = $.extend({}, opts.customParams, {});
  var socket = null, pinger = null;
  var tryDirectWebsocketPort = false;


  function initSocket() {
    var serverUrl = opts.serverUri;
    let pingCounter = 0;
    if (!tryDirectWebsocketPort) {
      serverUrl = serverUrl.substring(0, serverUrl.indexOf(':')) + "/ws";
      tryDirectWebsocketPort = true;
    }

    // NOTE: debug
    // socket = new WebSocket( "ws://localhost:5066/?" + encodeURIComponent(JSON.stringify(new PcChatPacketManager.PmAuthorizationPacket(params))));
    socket = new WebSocket((location.protocol == "https:" ? "wss" : "ws") + "://" + serverUrl + "/?" + encodeURIComponent(JSON.stringify(new PcChatPacketManager.PmAuthorizationPacket(params))));
    socket.binaryType = "arraybuffer";

    socket.onerror = function(event) {
      if (tryDirectWebsocketPort) {
        socket.onclose = null;
        initSocket();
      }
    }

    socket.onclose = function(event) {
      var reason = {};
      try {
        reason = JSON.parse(event.reason);
      } catch(e) {}
      if (reason.code === undefined) reason.code = closeReason.code;
      if (reason.message === undefined) reason.message = closeReason.message;
      if (reason.code === undefined) reason.code = 0;
      if (reason.message === undefined) reason.message = I18n.t("Connection closed");
      clearTimeout(pinger);
      pingCounter=0;
    };

    socket.onmessage = function(event) {
      tryDirectWebsocketPort = false;
      var packet = PcChatPacketManager.readPacket(event.data);
      if (packet != null) {
        if (packet.className == "setDisconnectionReason") {
          closeReason.code = packet.code;
          closeReason.message = packet.message;
        } else {
          pingCounter = 0;
          _chat.trigger(packet.className, packet);
        }
      }
    };

    clearTimeout(pinger);
    pinger = setTimeout(function ping() {
      if (!opts.waitingConfirmation) {
        pingCounter++;
        if (pingCounter > 5) {
          console.dir(opts);
          clearTimeout(pinger);
          _this.disconnect();
          _chat.trigger("Disconnect", 0, I18n.t("Ping timeout. Do you still have an Internet connection?"));
          return;
        }
        _this.sendPacket(new PcChatPacketManager.PingPacket());
      }
      pinger = setTimeout(ping, 1000);
    }, 1000);
  }
  initSocket();

  var lastPacketSent = 0;
  this.sendPacket = function(packet) {
    if (socket && socket.readyState == 1) {
      socket.send(JSON.stringify(packet));
      lastPacketSent = (new Date()).getTime();
    }
  };

  this.disconnect = function() {
    clearTimeout(pinger);
    if (socket) {
      socket.onclose = function () {};
      socket.close();
      socket = null;
      // delete socket;
    }
    _chat.callModFunction('videoPlayer', 'stop', []);
  };

  //destructor
  this.destructor = function() {
    this.disconnect();

    _chat = null;
    // delete _chat;

    _this = null;
    // delete _this;
  };

}