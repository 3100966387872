var privateChatContId,
    privateChatCont,
    _privatechat_settings = {};

$(function() {
  privateChatContId = _privatechat_settings.container;
  privateChatCont = $(privateChatContId);


  PrivateChat.getInstance(_privatechat_settings);
});